import React from "react";
import styles from "assets/styles.module.css";
import * as amplitude from "@amplitude/analytics-browser";
import Text from "./Text";
import Divider from "./Divider";
import { IconChevronLeft } from "@tabler/icons-react";
// import Avatar from "./Avatar";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetCoachQuery } from "redux/features/coachApi";
import { useDispatch } from "react-redux";
import { addSchoolId, clearAppelOffline } from "redux/features/userSlice";
import SchollCard from "./SchollCard";
// import { FaPhone, FaUser } from "react-icons/fa";
import { DrawerContext } from "context/DrawerContext";
import Logout from "pages/auth/Logout";

function Drawer() {
  const { opened, handleClose } = React.useContext(DrawerContext);

  const { userId, schoolId } = useGetUserInfo();

  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  const dispatch = useDispatch();

  const handleSelectSchool = (id) => {
    dispatch(addSchoolId(id));
    dispatch(clearAppelOffline());
  };
  return (
    <div
      className={styles.drawerContainer}
      style={{ right: opened ? "0vw" : "-100vw" }}
    >
      <div className={styles.row} style={{ marginTop: 10 }}>
        <IconChevronLeft
          size={25}
          style={{ marginRight: 10, cursor: "pointer" }}
          onClick={handleClose}
        />
        <Text>Parametre</Text>
      </div>

      <Divider style={{ margin: "10px 0" }} />

      {/* <Avatar width={70} height={70} />

      <div className={styles.row} style={{ marginBottom: 10, marginTop: 20 }}>
        <FaUser />
        <Text style={{ fontSize: 15, marginLeft: 10 }}>{coach?.fullName}</Text>
      </div>
      <div className={styles.row} style={{ marginBottom: 10 }}>
        <FaPhone />
        <Text style={{ fontSize: 15, marginLeft: 10 }}>
          +221 {coach?.phone}
        </Text>
      </div> */}

      <div className={styles.row} style={{ justifyContent: "space-between" }}>
        <Text style={{ margin: "10px 0", fontWeight: 600 }}>Version</Text>
        <Text style={{ margin: "10px 0", fontWeight: 600 }}>1.1.2</Text>
      </div>

      <Divider style={{ margin: "20px 0" }} />

      <Text style={{ margin: "10px 0", fontWeight: 600 }}>
        Choisir une Ecoles
      </Text>

      <div style={{ flexDirection: "column" }}>
        {coach?.schools?.map((item) => (
          <SchollCard
            key={item.id}
            {...item}
            onClick={() => {
              handleSelectSchool(item.id);
              handleClose();
              amplitude.track("Ecole", {
                coach: {
                  prenom_et_nom: coach?.fullName,
                  telephone: coach?.phone,
                },
                ecole: item?.name,
              });
            }}
            active={schoolId === item.id}
          />
        ))}
      </div>

      <Divider style={{ margin: "10px 0" }} />

      <div
        style={{
          // position: "absolute",
          // bottom: 0,
          // left: 0,
          // background: "rgba(255,0,0,0.7)",
          width: "100%",
          //   padding: 15,
          //   display: "flex",
          //   alignItems: "center",
        }}
      >
        <Logout handleLogout={handleClose} />
      </div>
    </div>
  );
}

export default Drawer;
