import Form from "components/Form";
import Text from "components/Text";
import { bgColorPrimary } from "constants";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdatePasswordMutation } from "redux/features/userApi";

function ForgetPassword() {
  const navigation = useNavigate();
  const loaction = useLocation();
  const from = loaction.state?.from.pathname || "/login";

  const [updatePassword] = useUpdatePasswordMutation();
  const [loading, setLoadin] = React.useState(false);

  const handleSubmit = async (value) => {
    if (value.password && value.password === value.confirmPassword) {
      setLoadin(true);
      await updatePassword({
        body: { phone: value?.phone, password: value?.password },
      });
      setLoadin(false);
      navigation(from, { replace: true });
    } else {
      alert("Les mots de passe ne correspondent pas");
    }
  };

  return (
    <div style={{ padding: 10, height: "100vh" }}>
      <Form onSubmit={handleSubmit}>
        <FaArrowLeft onClick={() => navigation(-1)} />

        <Text
          style={{
            fontSize: 22,
            fontWeight: 600,
            marginBottom: 30,
            marginTop: 20,
          }}
        >
          Recuperation de mot de passe
        </Text>

        <Form.Group name={"phone"} label={"Telephone"}>
          <Form.Input type={"tel"} placeholder={"77 *** ** **"} />
        </Form.Group>

        <Form.Group label={"Nouveau mot de passe"} name={"password"}>
          <Form.Input type="password" placeholder={"**********"} radius={10} />
        </Form.Group>

        <Form.Group label={"Confirm mot de passe"} name={"confirmPassword"}>
          <Form.Input type="password" placeholder={"**********"} radius={10} />
        </Form.Group>

        <Form.Button
          title={"Modifier"}
          fullidth
          style={{
            backgroundColor: bgColorPrimary,
            color: "#fff",
            fontWeight: 700,
            fontSize: 18,
          }}
          loading={loading}
        />
      </Form>
    </div>
  );
}

export default ForgetPassword;
