import React from "react";
import styles from "assets/styles.module.css";

function ScrollView({ style, ...props }) {
  return (
    <div style={{ ...style }} className={styles.scrollViewContainer} {...props}>
      {props.children}
    </div>
  );
}

export default ScrollView;
