import { ApiMananger } from "./apiMananger";

const sessionApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    addSession: build.mutation({
      query({ body, token }) {
        return {
          url: "/users/sessions/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Sessions"],
    }),
  }),
});

export const { useAddSessionMutation } = sessionApi;
