import React from "react";
import styles from "assets/styles.module.css";
import { FaBookOpen, FaExchangeAlt, FaHome, FaUser } from "react-icons/fa";
import Tab from "./TabLink";
import Text from "./Text";

function BottomTab() {
  return (
    <div className={styles.bottomTabContainer}>
      <Tab path={"/appel"} label={"Appel"} Icon={FaHome} />
      <Tab path={"/guide"} label={"Guide"} Icon={FaBookOpen} />
      <Tab
        path={"/corpus"}
        label={
          <Text style={{ whiteSpace: "noWrap", fontSize: 10 }}>Corpus</Text>
        }
        Icon={FaExchangeAlt}
      />
      {/* <Tab path={"/evaluation"} label={"Evaluation"} Icon={FaExchangeAlt} /> */}
      <Tab path={"/profile"} label={"Profile"} Icon={FaUser} />
    </div>
  );
}

export default BottomTab;
