import React from "react";

/**
 *
 * @param {{style: {}, w: Number, size: Number | String, color: String, onClick: ():Void => {} children: String}} param0
 * @returns
 */
function Text({ style, w, children, size, color, extraOption, onClick }) {
  return (
    <div
      style={{ color: color, fontWeight: w, fontSize: size ?? 15, ...style }}
      {...extraOption}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default Text;
