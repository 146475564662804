import React from "react";
import styles from "assets/styles.module.css";
import Text from "./Text";
import { IconArrowNarrowLeft } from "@tabler/icons-react";

function PageHeader(props) {
  return (
    <div className={styles.pageHeader}>
      <IconArrowNarrowLeft
        style={{
          marginRight: 15,
          // padding: 5,
        }}
        size={29}
        onClick={() => props.navigation(-1)}
      />
      <Text style={{ fontSize: 15 }}>{props.title}</Text>
    </div>
  );
}

export default PageHeader;
