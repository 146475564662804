import { API } from "./api";

/**
 * +----------------------------------------------------------------------------+
 *      USER API                                                                |
 * +----------------------------------------------------------------------------+
 */
export const LOGIN_PATH = "users/login/"; //POST
export const AUTH_PATH = "users"; //POST
export const LOGOUT_PATH = "users/logout/"; //GET
export const REGISTER_PATH = "coachs/"; //POST
export const UPDATE_PASSWORD_PATH = "users/password/";

/**
 * +----------------------------------------------------------------------------+
 *      NUBER VERIFIER API                                                      |
 * +----------------------------------------------------------------------------+
 */
export const NUMBER_VERIFIER_PATH = API + "verify/number/"; //GET + {number}

/**
 * +----------------------------------------------------------------------------+
 *      CSRF API                                                                |
 * +----------------------------------------------------------------------------+
 */
export const CSRF_TOKEN_PATH = API + "users/csrf/";

/**
 * +----------------------------------------------------------------------------+
 *      STUDENTS API                                                            |
 * +----------------------------------------------------------------------------+
 */
export const STUDENTS_API = "eleves/coach"; //GET + {id}

/**
 * +----------------------------------------------------------------------------+
 *      CALL STUDENTS API                                                       |
 * +----------------------------------------------------------------------------+
 */
export const APPEL_API = "appels"; //GET + {id}

/**
 * +----------------------------------------------------------------------------+
 *      FICHES API                                                       |
 * +----------------------------------------------------------------------------+
 */
export const FICHE_API = "fiches"; //GET + {id}

/**
 * +----------------------------------------------------------------------------+
 *      EVALUTIONS API                                                       |
 * +----------------------------------------------------------------------------+
 */
export const EVALUATION_API = "evaluations"; //GET + {id}
