import { useState } from "react";
import Button from "components/Button";
import Modal from "react-bootstrap/Modal";
import Form from "components/Form";
import { bgColorPrimary } from "constants";
import { RADIUS } from "constants";
import { useAddComprehensionMutation } from "redux/features/comprehensionApi";
// import * as amplitude from "@amplitude/analytics-browser";
import Text from "components/Text";

function EvaluationEleveModal(props) {
  const [show, setShow] = useState(false);
  const [appreciation, setAppreciation] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addComprehension] = useAddComprehensionMutation();

  const handleLogin = async (value) => {
    setLoading(true);
    // console.log({
    //   ...value,
    //   eleveId: props?.eleveId ?? null,
    //   ecoleId: props.schoolId ?? null,
    //   ficheId: props.ficheId ?? null,
    //   coachId: props?.coachId ?? null,
    //   appreciation,
    // });

    const data = {
      ...value,
      eleveId: props?.eleveId ?? null,
      ficheId: props.ficheId ?? null,
      schoolId: props.schoolId ?? null,
      coachId: props?.coachId ?? null,
      appreciation,
    };

    try {
      await addComprehension({
        body: data,
        token: props.token,
      });
      //   console.log(response);
      setLoading(false);
      handleClose();
      // amplitude.track("EvaluationEleve", {
      //   coach: {
      //     prenom_et_nom: props.coach?.fullName,
      //     telephone: props.coach?.phone,
      //   },
      //   ecole: props.coach?.schools.find((ecole) => ecole.id === props.schoolId)
      //     ?.name,
      //   eleve: {
      //     prenom_et_nom: props.eleve?.firstName + " " + props.eleve?.lastName,
      //     note: value?.note,
      //     appreciation: appreciation ?? "",
      //     // classe: props.eleve?.student?.classe?.name,
      //   },
      //   fiche: {
      //     fiche: props.fiche?.data?.name,
      //     niveau: props.fiche?.data?.niveaux_list?.name,
      //   },
      // });
      setAppreciation("");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleAddComprehension = () => {
    props.value(appreciation);
    handleClose();
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <Button
        title={<Text style={{ fontSize: 11 }}>Signaler lacune</Text>}
        style={{ width: 115 }}
        onClick={handleShow}
      />

      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        style={{ marginTop: 50 }}
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: bgColorPrimary, color: "#fff", height: 40 }}
        >
          <Modal.Title style={{ fontSize: 12 }}>Evaluer l'eleve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddComprehension}>
            {/* <Form.Group
              name={"note"}
              label={"La note de l'eleve"}
              error={""}
              defaultValue={"lalal"}
              style={{
                borderRadius: RADIUS,
              }}
            >
              <Form.Input
                placeholder="note"
                type={"number"}
                required
                maxLength={9}
                radius={0}
              />
            </Form.Group> */}
            <textarea
              placeholder="Mettez ici l'appreciation"
              style={{
                width: "100%",
                border: "1px solid red",
                padding: 10,
                borderRadius: 5,
                marginBottom: 10,
                fontSize: 14,
              }}
              value={appreciation}
              onChange={(e) => setAppreciation(e.target.value)}
              rows={2}
            ></textarea>

            <Form.Button
              title={
                <Text style={{ fontWeight: 700, fontSize: 14 }}>
                  Valider l'evaluation
                </Text>
              }
              fullidth
              style={{
                backgroundColor: bgColorPrimary,
                color: "#fff",
                height: 32,
                borderRadius: RADIUS,
              }}
              loading={loading}
            />
          </Form>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
}

export default EvaluationEleveModal;
