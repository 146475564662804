import { RADIUS } from "constants";
import React from "react";
import { RotatingLines } from "react-loader-spinner";
import Text from "./Text";

/**
 *
 * @param {{title: String | Number, onChange: Function, errorMessage: String, Icon: {name: String, color: String, size: Number}}} param0
 * @returns
 */
function Button({
  title,
  onClick,
  buttonLabelStyle,
  icon,
  color,
  style,
  bgColor,
  height,
  radius,
  disabled,
  loading,
}) {
  return (
    <button
      style={{
        border: "1px solid " + bgColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: bgColor ?? "#fff",
        height: height ?? 35,
        overflow: "hidden",
        paddingLeft: 10,
        paddingRight: 10,
        cursor: "pointer",
        color: color ?? "black",
        borderRadius: radius ?? RADIUS,
        opacity: disabled ? 0.3 : 1,
        ...style,
      }}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? (
        <RotatingLines
          strokeColor="#fff"
          strokeWidth="5"
          animationDuration="0.75"
          width="25"
          visible={true}
        />
      ) : (
        <>
          {icon?.name && icon.possition !== "right" && (
            <icon.name
              color={icon?.color ?? color ?? "gray"}
              style={{}}
              size={icon?.size ?? 15}
            />
          )}
          <Text
            style={{
              fontSize: 15,
              marginLeft: 7,
              marginRight: 7,
              ...buttonLabelStyle,
            }}
          >
            {title}
          </Text>
          {icon?.name && icon.possition === "right" && (
            <icon.name
              color={icon?.color ?? color ?? "gray"}
              style={{}}
              size={icon?.size ?? 15}
            />
          )}
        </>
      )}
    </button>
  );
}

export default Button;
