import useGetUserInfo from "hooks/useGetUserInfo";
import styles from "assets/styles.module.css";
import React from "react";
import { useGetCoachQuery } from "redux/features/coachApi";
import { useGetElevesQuery } from "redux/features/eleveApi";
import Avatar from "components/Avatar";
import Text from "components/Text";
import { FaHome, FaPhone, FaUser } from "react-icons/fa";
import Divider from "components/Divider";
// import SchollCard from "components/SchollCard";
// import { useDispatch } from "react-redux";
// import { addSchoolId } from "redux/features/userSlice";
import { TailSpin } from "react-loader-spinner";
import { bgColorPrimary } from "constants";
// import { clearAppelOffline } from "redux/features/AppelSlice";

function Profile() {
  const { userId, schoolId } = useGetUserInfo();

  const { data: coach, isLoading } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  const { data: studentsData } = useGetElevesQuery(
    { coachId: userId, schoolId },
    {
      refetchOnFocus: true,
    }
  );

  // console.log({ coach });

  // const dispatch = useDispatch();

  // const handleSelectSchool = (id) => {
  //   dispatch(addSchoolId(id));
  //   dispatch(clearAppelOffline());
  // };

  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <TailSpin
        height="60"
        width="60"
        color={bgColorPrimary}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  ) : (
    <div
      style={{
        color: "rgba(0,00,0,0.7)",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        // marginTop: 60,
        padding: 10,
      }}
    >
      <Avatar
        src={"icons/avatar.png"}
        width={70}
        height={70}
        style={{ marginBottom: 15 }}
      />
      <div className={styles.row} style={{ marginBottom: 10 }}>
        <FaUser />
        <Text style={{ fontSize: 15, marginLeft: 10 }}>{coach?.fullName}</Text>
      </div>
      <div className={styles.row} style={{ marginBottom: 10 }}>
        <FaPhone />
        <Text style={{ fontSize: 15, marginLeft: 10 }}>{coach?.phone}</Text>
      </div>
      <div className={styles.row} style={{ marginBottom: 10 }}>
        <FaHome />
        <Text style={{ fontSize: 15, marginLeft: 10 }}>
          {coach?.villeId?.name}
        </Text>
      </div>

      <Divider style={{ margin: "10px 0" }} />

      <div className={styles.row}>
        <Text style={{ margin: "5px 0", fontWeight: 600 }}>Mes classes</Text>
      </div>
      <div style={{ flexDirection: "column", width: "100%", marginLeft: 20 }}>
        {coach?.classes && coach?.classes.length > 0 ? (
          coach?.classes?.map((item) => (
            <p key={item.id} style={{ marginBottom: 7, fontSize: 13 }}>
              - {item?.name}
            </p>
          ))
        ) : (
          <Text>Vous n'avez pas de classe</Text>
        )}
      </div>

      <Divider style={{ margin: "10px 0" }} />

      <div className={styles.row}>
        <Text style={{ margin: "5px 0", fontWeight: 600 }}>Mes niveaux</Text>
      </div>
      <div style={{ flexDirection: "column", width: "100%", marginLeft: 20 }}>
        {coach?.niveaux?.map((item) => (
          <p key={item.id} style={{ marginBottom: 7, fontSize: 13 }}>
            {" "}
            - {item?.name}
          </p>
        ))}
      </div>

      <Divider style={{ margin: "10px 0" }} />

      <div className={styles.row}>
        <Text style={{ margin: "5px 0", fontWeight: 600 }}>Mes écoles</Text>
      </div>
      <div style={{ flexDirection: "column", width: "100%", marginLeft: 10 }}>
        {coach?.schools?.map((item) => (
          <div
            key={item.id}
            className={styles.row}
            style={{ justifyContent: "space-between", marginBottom: 7 }}
          >
            <p
              style={{
                fontSize: item.id === schoolId ? 14 : 13,
                fontWeight: item.id === schoolId && 600,
                display: "flex",
              }}
            >
              {" "}
              - {item?.name}
              {item?.id === schoolId && (
                <span
                  style={{
                    fontSize: 12,
                    marginLeft: 5,
                    color: "green",
                  }}
                >
                  active
                </span>
              )}
            </p>
            {item.id === schoolId && (
              <p style={{ fontSize: 13, marginRight: 20 }}>
                {studentsData?.data?.length} eleves
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Profile;
