import { ApiMananger } from "./apiMananger";

const coachApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getCoach: build.query({
      query: (id) => `/coachs/${id}/`,
      providesTags: ["Coachs"],
    }),
    addCoach: build.mutation({
      query(body) {
        return {
          url: `/coachs`,
          method: "POST",
          headers: {},
          body,
        };
      },
      invalidatesTags: ["Coachs"],
    }),
    updateCoach: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `coachs/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Coachs"],
    }),
  }),
});

export const { useAddCoachMutation, useGetCoachQuery, useUpdateCoachMutation } =
  coachApi;
