import React from "react";
// import logoGenimi from "icons/icon1.png"

function Logo({ blue, width, height, alt, style }) {
  return (
    <img
      src={blue ? "icons/iconGenimi.png" : "icons/icon1.png"}
      style={{ width: width ?? 35, height: height ?? 35, ...style }}
      alt={alt ?? "Logo"}
    />
  );
}

export default Logo;
