import React from "react";
import styles from "assets/styles.module.css";
import { bgColorPrimary } from "constants";
import Text from "./Text";
import Badge from "./Badge";
import { IconSearch, IconUserSearch, IconX } from "@tabler/icons-react";

function AppelListHeader({ active, onClick, data, onSearch }) {
  const [search, setSearch] = React.useState(false);

  return (
    <div
      className={styles.row}
      style={{
        position: "sticky",
        top: 0,
        height: 50,
        backgroundColor: "#fff",
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        padding: "0 10px",
        overflowX: "scroll",
      }}
    >
      {search ? (
        <>
          <div
            style={{
              padding: "6px 5px",
              borderRadius: 20,
              fontWeight: 700,
              marginRight: 15,
              border: "1px solid rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "rgba(0,0,0,0.5)",
              width: "100%",
            }}
          >
            <IconUserSearch style={{}} size={17} />
            <input
              style={{
                all: "unset",
                height: "100%",
                marginLeft: 10,
                fontWeight: 400,
                fontSize: 13,
                width: "100%",
              }}
              placeholder={"Recherche"}
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
          <IconX
            style={{ marginRight: 5 }}
            size={20}
            onClick={() => setSearch(false)}
          />
        </>
      ) : (
        <>
          <div
            style={{
              padding: "6px 5px",
              borderRadius: 20,
              fontWeight: 700,
              marginRight: 15,
              border: "1px solid rgba(0,0,0,0.2)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "rgba(0,0,0,0.5)",
              // width: "100%",
              width: 45,
              height: 35,
            }}
            onClick={() => setSearch(true)}
          >
            <IconSearch style={{}} size={20} />
          </div>
          {["Tout", "Present", "Absent"]?.map((item, key) => (
            <div
              key={key}
              style={{
                padding: "6px 15px",
                borderRadius: 20,
                backgroundColor: active === item ? bgColorPrimary : "#fff",
                color: active === item ? "#fff" : "rgba(0,0,0,0.7)",
                fontWeight: 700,
                marginRight: 15,
                border: "1px solid rgba(0,0,0,0.2)",
                cursor: "pointer",
                position: "relative",
              }}
              onClick={() => onClick(item)}
            >
              <Text style={{ fontWeight: 400 }}>
                {item === "Tout" ? "Tout" : item}
              </Text>
              <Badge
                title={
                  data?.filter((appel) =>
                    item === "Tout"
                      ? appel
                      : item === "Absent"
                      ? appel?.status?.includes("ABSENT")
                      : appel?.status?.includes("PRESENT") ||
                        (appel?.status?.includes("RETARD") && appel)
                  ).length ?? 0
                }
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default AppelListHeader;
