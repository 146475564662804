import PageHeader from "components/PageHeader";
import styles from "assets/styles.module.css";
import ScrollView from "components/ScrollView";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Text from "components/Text";
import Avatar from "components/Avatar";
import {
  useGetEleveQuery,
  useUpdateEleveMutation,
} from "redux/features/eleveApi";
import Divider from "components/Divider";
import Button from "components/Button";

function StudentDetails() {
  const params = useParams();
  const navigation = useNavigate();

  const { data, isLoading, refetch } = useGetEleveQuery(parseInt(params.id), {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });
  const [updateEleve] = useUpdateEleveMutation();
  const [loading, setLoading] = useState(false);

  // console.log({ data });

  const handleSuggest = async () => {
    setLoading(true);
    try {
      const newSugges = {
        id: parseInt(params.id),
        boursier: "ELIGIBLE",
      };
      await updateEleve(newSugges);
      await refetch();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        title={isLoading ? "..." : data?.firstName + " " + data?.lastName}
        navigation={navigation}
      />
      <ScrollView style={{ padding: "1rem" }}>
        {isLoading ? (
          "Loading"
        ) : (
          <>
            <Avatar
              src={"../../icons/avatar.png"}
              width={70}
              height={70}
              style={{ marginBottom: 15 }}
            />
            <div
              className={styles.row}
              style={{ justifyContent: "space-between" }}
            >
              <Text style={{ fontSize: 17, fontWeight: "400" }}>
                Paiement du mois
              </Text>
              <Text
                style={{
                  color: params?.status === "NON" ? "red" : "green",
                }}
              >
                {params?.status}
              </Text>
            </div>
            <Divider style={{ margin: "14px 0" }} />
            <div
              className={styles.row}
              style={{ justifyContent: "space-between" }}
            >
              <Text style={{ fontSize: 17, fontWeight: "400" }}>Parrain</Text>
              <Text style={{}}>
                {data?.parrainId ? "Oui" : "Pas de parain"}
              </Text>
            </div>
            <Divider style={{ margin: "14px 0" }} />
            <div
              className={styles.row}
              style={{ justifyContent: "space-between" }}
            >
              <Text style={{ fontSize: 17, fontWeight: "400" }}>Boursier</Text>
              <Text style={{}}>
                {data?.boursier === "NON" ? (
                  <Button
                    title={"suggere une bourse"}
                    style={{ height: 30 }}
                    onClick={handleSuggest}
                    loading={loading}
                  />
                ) : (
                  <Text style={{ color: "green" }}>{data?.boursier}</Text>
                )}
              </Text>
            </div>
          </>
        )}
      </ScrollView>
    </div>
  );
}

export default StudentDetails;
