import { ApiMananger } from "./apiMananger";

const corpusApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getCorpus: build.query({
      query: ({ limit, offset }) => `/corpus/?limit=${limit}&offset=${offset}`,
      providesTags: ["Corpus"],
    }),
    addCorpus: build.mutation({
      query(body) {
        return {
          url: `/corpus`,
          method: "POST",
          headers: {},
          body,
        };
      },
      invalidatesTags: ["Corpus"],
    }),
    updateCorpus: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `corpus/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Corpus"],
    }),
  }),
});

export const {
  useAddCorpusMutation,
  useGetCorpusQuery,
  useUpdateCorpusMutation,
} = corpusApi;
