import Button from "components/Button";
import styles from "assets/styles.module.css";
import Divider from "components/Divider";
import Loader from "components/Loader";
import * as amplitude from "@amplitude/analytics-browser";
import PageHeader from "components/PageHeader";
import ScrollView from "components/ScrollView";
import Text from "components/Text";
import useGetUserInfo from "hooks/useGetUserInfo";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddEvalutionMutation,
  useGetEvaluationQuery,
} from "redux/features/evaluationApi";
import { useGetElevesQuery } from "redux/features/eleveApi";
import { useGetCoachQuery } from "redux/features/coachApi";
import {
  useGetCoachFichesQuery,
  useGetFicheQuery,
} from "redux/features/ficheApi";
import TabLink from "components/TabLink";
import { FaFile, FaSchool, FaUsers } from "react-icons/fa";
import { bgColorPrimary } from "constants";
// import { useGetCoachAppelsQuery } from "redux/features/appelApi";
import Empty from "components/Empty";
import Avatar from "components/Avatar";

import avatarImage from "assets/images/avatar.png";
// import SearchBarPage from "components/SearchBarPage";
import EvaluationEleveModal from "./EvaluationEleveModal";
import {
  useAddComprehensionMutation,
  useGetComprehensionWithElevesQuery,
} from "redux/features/comprehensionApi";
import { RotatingLines } from "react-loader-spinner";

function FicheEvaluation(props) {
  const params = useParams();
  const navigation = useNavigate();

  const { userId, schoolId, csrfToken } = useGetUserInfo();

  const [addEvalution] = useAddEvalutionMutation();

  const { data: fiche } = useGetFicheQuery(params.id);

  const { data, isLoading } = useGetEvaluationQuery({
    ficheId: params.id,
    schoolId,
  });
  const { refetch: fichesRefresh } = useGetCoachFichesQuery({
    coachId: userId,
    schoolId,
  });
  const { data: studentsData } = useGetElevesQuery(
    { coachId: userId, schoolId },
    {
      refetchOnFocus: true,
    }
  );
  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  const [addComprehension] = useAddComprehensionMutation();

  const {
    data: comprehensionData,
    isLoading: comprehensionLoadind,
    isFetching,
  } = useGetComprehensionWithElevesQuery(
    {
      coachId: userId,
      fileId: parseInt(params?.id),
      schoolId,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  // const { data: coachAppel } = useGetCoachAppelsQuery(
  //   { coachId: userId, schoolId },
  //   {
  //     refetchOnFocus: true,
  //   }
  // );

  // console.log(comprehensionData);

  const [evaluationData, setEvaluationData] = React.useState([]);

  const [activeTab, setActiveTab] = React.useState("fiches");

  const [validationLoading, setValidationLoading] = React.useState(false);
  const [nombre, setNombre] = React.useState(0);

  const handleEvaluation = async () => {
    setValidationLoading(true);
    try {
      await addEvalution({
        body: {
          eleveCompris: nombre,
          status: "VALIDE",
          evaluation: data?.data[0].id,
          coach: userId,
          schoolId,
        },
        token: csrfToken,
      });
      // console.log("success", evaluation);

      setValidationLoading(false);
      amplitude.track("Evaluation", {
        coach: {
          prenom_et_nom: coach?.fullName,
          telephone: coach?.phone,
        },
        nombreEleveNomCompris: Number(nombre),
      });
      await fichesRefresh();
      navigation("/guide");
    } catch (error) {
      console.log(error);
      setValidationLoading(false);
    }
  };

  const handleSendEvaluation = async () => {
    let newData = [];

    /**
     * PRESENT
     * MESSAGE: L'eleve a bien bien suivis et compris la fiche ${fiche name et fiche niveaux}
     */

    comprehensionData?.data.map((eleve) =>
      !evaluationData.find((el) => el.eleveId === eleve?.id)
        ? eleve?.appel?.status !== "ABSENT"
          ? (newData = [
              ...newData,
              {
                eleveId: eleve?.id,
                status: eleve?.appel?.status,
                valide: true,
                // appreciation: null,
                // appreciation: `L'eleve a bien bien suivi et compris la fiche : ${fiche?.data?.niveaux_list?.name} - ${fiche?.data?.name}`,
              },
            ])
          : // null
            (newData = [
              ...newData,
              {
                eleveId: eleve?.id,
                status: eleve?.appel?.status,
                appreciation: `ABSENT`,
                valide: false,
                // appreciation: `L'eleve n'a pas suivi la fiche : ${fiche?.data?.niveaux_list?.name} - ${fiche?.data?.name}`,
              },
            ])
        : null
    );
    console.log({
      ficheId: parseInt(params.id),
      coachId: userId,
      schoolId,
      evaluations: [...evaluationData, ...newData],
    });

    try {
      const response = await addComprehension({
        body: {
          ficheId: parseInt(params.id),
          coachId: userId,
          schoolId,
          evaluations: [...evaluationData, ...newData],
        },
        token: csrfToken,
      });

      amplitude.track("EvaluationEleve", {
        coach: {
          prenom_et_nom: coach?.fullName,
          telephone: coach?.phone,
        },
        ecole: coach?.schools.find((ecole) => ecole.id === schoolId)?.name,
        fiche: {
          fiche: fiche?.data?.name,
          niveau: fiche?.data?.niveaux_list?.name,
        },
      });

      console.log(response);
    } catch (error) {
      console.log(error);
    }

    // const dataSended = {};
  };

  return (
    <div>
      <PageHeader title={"Evaluation de la fiche"} navigation={navigation} />

      <div style={{}} className={styles.GuideTab}>
        <TabLink
          label={"Evaluation de la Fiche"}
          Icon={FaFile}
          style={{
            width: "50%",
            backgroundColor: bgColorPrimary,
            color: "#fff",
            borderBottom: `3px solid ${
              activeTab === "fiches" ? "#fff" : bgColorPrimary
            }`,
          }}
          onclick={() => setActiveTab("fiches")}
        />
        <TabLink
          label={"Evaluation d'eleve"}
          Icon={FaUsers}
          style={{
            width: "50%",
            backgroundColor: bgColorPrimary,
            color: "#fff",
            borderBottom: `3px solid ${
              activeTab !== "fiches" ? "#fff" : bgColorPrimary
            }`,
          }}
          onclick={() => setActiveTab("eleves")}
        />
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        activeTab === "fiches" &&
        (data?.data[0]?.contenu ? (
          <div className={styles.ficheContent}>
            <ScrollView style={{ paddingTop: 10, paddingBottom: 180 }}>
              <div className={styles.row}>
                <Text style={{ fontSize: 30, fontWeight: 700 }}>*</Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    textDecoration: "underline",
                    marginBottom: 10,
                    marginLeft: 5,
                  }}
                >
                  {params.materiel}
                </Text>
              </div>
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: 400,
                  marginBottom: 5,
                }}
              >
                {params.objectifPedagogique}
              </Text>

              <Divider style={{ margin: "20px 0" }} />

              <div className={styles.row} style={{ marginBottom: 10 }}>
                <div className={styles.dot}></div>
                <Text
                  style={{
                    fontWeight: 500,
                    fontSize: 13,
                  }}
                >
                  Evaluation
                </Text>
              </div>
              <div
                style={{
                  borderRadius: 5,
                  backgroundColor: "rgba(0,0,0,0.07)",
                  padding: "10px 5px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<div>${data?.data[0]?.contenu}</div>`,
                  }}
                  style={{ fontSize: 13, lineHeight: 1.3 }}
                />
              </div>

              <Divider style={{ margin: "20px 0" }} />

              <div className={styles.row} style={{ marginBottom: 10 }}>
                <div className={styles.dot}>.</div>
                <Text
                  style={{
                    fontWeight: 500,
                    fontSize: 13,
                  }}
                >
                  Nombre d'eleves qui n'ont pas compris
                </Text>
              </div>

              <div>
                <input
                  style={{
                    // all: "unset",
                    height: 40,
                    marginTop: 5,
                    padding: "1px 5px",
                    borderBottom: `1px solid ${
                      studentsData?.data.length < Number(nombre)
                        ? "red"
                        : "black"
                    }`,
                    width: "100%",
                    borderRadius: 5,
                  }}
                  onChange={(e) => setNombre(e.target.value)}
                  value={nombre}
                  placeholder={"Nombre d'eleves n'ont compris"}
                  type={"number"}
                />

                {studentsData?.data.length < Number(nombre) ? (
                  <Text style={{ color: "red", fontSize: 13 }}>
                    Le nombre saisie ne peut pas etre supperieur au nombre
                    d'eleve
                  </Text>
                ) : Number(nombre) < 0 ? (
                  <Text style={{ color: "red", fontSize: 13 }}>
                    Le nombre saisie ne peut pas etre inferieur a 0
                  </Text>
                ) : null}

                <Button
                  title={"Valider l'evaluation"}
                  bgColor={"green"}
                  color={"#fff"}
                  style={{ margin: "10px 0" }}
                  loading={validationLoading}
                  onClick={handleEvaluation}
                  disabled={
                    studentsData?.data.length < Number(nombre) ||
                    Number(nombre) < 0
                  }
                />
              </div>
            </ScrollView>
          </div>
        ) : (
          <div className={styles.center}>
            <Text>Cette fiche n'a pas d'evaluation</Text>
          </div>
        ))
      )}

      {isLoading ? (
        <Loader />
      ) : (
        activeTab !== "fiches" && (
          <ScrollView>
            {/* <SearchBarPage /> */}
            <div style={{ marginTop: 50 }}></div>
            {comprehensionLoadind || isFetching ? (
              <Empty title={"Chargement en cours ..."} />
            ) : comprehensionData?.data.length > 0 ? (
              comprehensionData?.data
                // .filter((eleve) => eleve.status !== "ABSENT")
                .map((eleve) => (
                  <div
                    key={eleve?.id}
                    style={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      marginBottom: 10,
                      borderRadius: 5,
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "1px solid rgba(0,0,0,0.15)",
                        padding: 10,
                        borderRadius: 5,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Avatar
                          width={40}
                          height={40}
                          src={avatarImage}
                          style={{ marginRight: 7 }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: 700,
                              color: "black",
                              fontSize: 14,
                            }}
                          >
                            {/* {eleve?.student?.firstName}{" "}
                            {eleve?.student?.lastName} */}
                            {eleve?.firstName} {eleve?.lastName}
                          </Text>
                          {/* <div
                            className={styles.row}
                            style={{ marginTop: 6, marginBottom: 4 }}
                          >
                            <FaSchool size={16} />
                            <Text style={{ marginLeft: 5, fontSize: 12 }}>
                              {eleve?.school?.name ?? "None"}
                            </Text>
                          </div> */}
                          {/* <Text style={{ fontSize: 12 }}>
                            {eleve?.student?.classe?.name}
                          </Text> */}
                          {eleve?.appel ? (
                            <Text
                              style={{
                                fontSize: 13,
                                color:
                                  eleve?.appel?.status === "PRESENT"
                                    ? "green"
                                    : eleve?.appel?.status === "ABSENT"
                                    ? "red"
                                    : "orange",
                              }}
                            >
                              {eleve?.appel?.status.toLowerCase() ??
                                "None appeler"}
                            </Text>
                          ) : (
                            <Text
                              style={{
                                fontSize: 13,
                              }}
                            >
                              Non appeler
                            </Text>
                          )}
                        </div>
                      </div>

                      <div className={styles.row}>
                        {/* <Text
                          style={{
                            fontSize: 13,
                            color:
                              eleve.status === "PRESENT"
                                ? "green"
                                : eleve.status === "ABSENT"
                                ? "red"
                                : "orange",
                          }}
                        >
                          {eleve.status.toLowerCase() ?? "none"}
                        </Text> */}
                        {/* {eleve?.appel?.status === "PRESENT" ||
                        eleve?.appel?.status === "RETARD" ? ( */}
                        {eleve?.comprehension ? (
                          eleve?.comprehension?.valide ? (
                            <Text style={{ fontSize: 14, fontWeight: 600 }}>
                              Fiche validée
                            </Text>
                          ) : (
                            <Text style={{ fontSize: 14, fontWeight: 600 }}>
                              Fiche non validée
                            </Text>
                          )
                        ) : eleve?.appel &&
                          eleve?.appel?.status === "ABSENT" ? null : (
                          eleve?.appel && (
                            <EvaluationEleveModal
                              validate={eleve?.comprehension?.valide ?? false}
                              schoolId={schoolId}
                              coachId={userId}
                              eleveId={eleve?.id}
                              ficheId={parseInt(params.id)}
                              token={csrfToken}
                              coach={coach}
                              eleve={eleve}
                              fiche={fiche}
                              value={(data) => {
                                if (
                                  evaluationData.find(
                                    (evaluation) =>
                                      evaluation.eleveId === eleve?.id
                                  )
                                ) {
                                  const restEvaluation = evaluationData.filter(
                                    (evaluation) =>
                                      evaluation.eleveId !== eleve?.id
                                  );

                                  setEvaluationData([
                                    ...restEvaluation,
                                    {
                                      ...evaluationData.find(
                                        (evaluation) =>
                                          evaluation.eleveId === eleve?.id
                                      ),
                                      appreciation: data,
                                    },
                                  ]);
                                } else {
                                  setEvaluationData([
                                    ...evaluationData,
                                    {
                                      eleveId: eleve?.id,
                                      status: eleve?.appel?.status,
                                      appreciation: data,
                                      valide: false,
                                    },
                                  ]);
                                }
                              }}
                            />
                          )
                        )}
                        {/* {eleve?.appel &&
                          !eleve?.comprehension &&
                          eleve?.appel?.status !== "ABSENT" && (
                            <EvaluationEleveModal
                              schoolId={schoolId}
                              coachId={userId}
                              eleveId={eleve?.id}
                              ficheId={parseInt(params.id)}
                              token={csrfToken}
                              coach={coach}
                              eleve={eleve}
                              fiche={fiche}
                            />
                          )}
                        {eleve?.comprehension && (
                          <Text
                            style={{
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {eleve?.comprehension?.note ?? "pas de note"}
                          </Text>
                        )} */}
                      </div>
                    </div>

                    {/* <div style={{}}>
                      <textarea
                        placeholder="Mettre la comprehension..."
                        rows={2}
                        style={{ width: "100%", padding: 5, fontSize: }}
                      ></textarea>
                    </div> */}
                  </div>
                ))
            ) : (
              <Empty title={"IL n'y a pas encore d'appel"} />
            )}
            <br />
            {!comprehensionData?.data[0].comprehension &&
              comprehensionData?.data.length > 0 &&
              comprehensionData?.data[0]?.appel?.status && (
                <Button
                  title={"Envoyer la liste"}
                  onClick={handleSendEvaluation}
                  style={{}}
                />
              )}
          </ScrollView>
        )
      )}
    </div>
  );
}

export default FicheEvaluation;
