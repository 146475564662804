import React from "react";

function HacDivider({ h, mt, mb, my, color, style }) {
  return (
    <div
      style={{
        height: h ?? 1,
        flex: 1,
        backgroundColor: color ?? "rgba(217, 217, 217, 0.707)",
        marginTop: mt ?? my,
        marginBottom: mb ?? my,

        ...style,
      }}
    ></div>
  );
}

export default HacDivider;
