import { STUDENTS_API } from "../../routes/api.paths";
import { ApiMananger } from "./apiMananger";

const eleveApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getEleve: build.query({
      query: (id) => `eleves/${id}/`,
      invalidatesTags: ["Eleves"],
    }),
    getEleves: build.query({
      query: (id) => `${STUDENTS_API}/${id.coachId}/${id.schoolId}/`,
      providesTags: ["Eleves", "Appels"],
    }),
    updateEleve: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `eleves/${id}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Eleves"],
    }),
    deleteEleve: build.mutation({
      query(id) {
        return {
          url: `${STUDENTS_API}/students/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Eleves"],
    }),
  }),
});

export const {
  useGetEleveQuery,
  useGetElevesQuery,
  useUpdateEleveMutation,
  useDeleteEleveMutation,
} = eleveApi;
