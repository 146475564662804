import Button from "components/Button";
import Input from "components/Input";
import PageHeader from "components/PageHeader";
import Text from "components/Text";
import useGetUserInfo from "hooks/useGetUserInfo";
import * as amplitude from "@amplitude/analytics-browser";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAddPaiementMutation } from "redux/features/paiementApi";
import { useGetCoachQuery } from "redux/features/coachApi";
import Divider from "components/Divider";
import axios from "axios";
import ScrollView from "components/ScrollView";

function Payement() {
  const params = useParams();
  const navigation = useNavigate();

  const { userId, csrfToken } = useGetUserInfo();
  const [addPaiement, { isLoading }] = useAddPaiementMutation();
  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  const [montant, setMontant] = React.useState("");
  const [month, setMonth] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [waveLoading, setWaveLoading] = React.useState(false);
  const [waveUrl, setWaveUrl] = React.useState("");

  const handlePay = async () => {
    setLoading(true);
    console.log({ montant: parseInt(montant), month: month.split("-")[1] });
    try {
      const paiement = await addPaiement({
        body: {
          studentId: params?.id,
          faitPar: userId,
          montant: parseInt(montant),
          month: month.split("-")[1],
          status: "PAYE",
        },
        token: csrfToken,
      });

      if (paiement?.error) {
        setLoading(false);
        return setError("Une erreur c'est produite");
      }
      amplitude.track("Paiement", {
        coach: {
          prenom_et_nom: coach?.fullName,
          telephone: coach?.phone,
        },
        eleve: params.name,
        montant: montant,
        mois: month,
      });
      // console.log({ paiement });
      setLoading(false);
      navigation(-1);
    } catch (error) {
      console.log(error);
      setError("Une erreur c'est produite");
      setLoading(false);
    }
  };

  const handlePayWave = async () => {
    setWaveLoading(true);
    try {
      const response = await axios.get(
        `https://genimi-admin.bakeli.tech/payements/checkout/`,
        {
          headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
      
      if (response.data) {

        const deserialize =  JSON.parse(response.data.data)
        const _url = deserialize.wave_launch_url
        window.open(JSON.parse(response.data.data).wave_launch_url, "_blank");
        // window.location.href(_url);
        // setWaveUrl(JSON.parse(response.data.data).wave_launch_url);
        // console.log(deserialize.wave_launch_url.split("&")[0].split('?'));
        // console.log(_url);
      }
      setWaveLoading(false);
    } catch (error) {
      setWaveLoading(false);
      console.log({ error });
    }
  };

  return (
    <div>
      <PageHeader title={"Paiement"} navigation={navigation} />

      <ScrollView>
        <div style={{ padding: "10px 7px" }}>
          <Text style={{ fontWeight: 500, fontSize: 20, margin: "30px 0" }}>
            Veuillez entrer le paiement
          </Text>

          <Input
            onChange={setMonth}
            value={month}
            label="Month-344"
            errorMessage={error}
            type={"month"}
            style={{ marginBottom: 10 }}
          />

          <Input
            onChange={(e) => {
              if (e && (e < 0 || !Number(e))) {
                setError("Veuillez mettre un montant valid");
              } else {
                setError(null);
              }
              setMontant(e);
            }}
            value={montant}
            label="Mettre le montant a payer"
            errorMessage={error}
            type={"number"}
          />
          <Button
            title={"Envoyer le paiement"}
            style={{ marginTop: 20 }}
            bgColor={"green"}
            color={"#fff"}
            onClick={handlePay}
            loading={loading}
            disabled={error || !montant}
          />

          <Divider style={{ margin: "30px auto", width: "80%" }} />
          <img src={"https://www.wave.com/img/nav-logo.png"} height={45} />
          <Button
            title={"Payer par Wave"}
            style={{ marginTop: 20 }}
            bgColor={"#1AB3E5"}
            color={"#fff"}
            onClick={handlePayWave}
            loading={waveLoading}
          />
        </div>
        {/* <div style={{ padding: "10px 7px" }}>
          {waveUrl && (
          <iframe
            key={1}
            title={"Wave paiement"}
            src={
              waveUrl ??
              "https://pay.wave.com/c/cos-1e3tydh3g113t?a=2000&c=XOF&m=Genimi"
            }
            width={"100%"}
            height={300}
            style={{ borderRadius: 10, border: "none" }}
          />
          )}
        </div> */}
      </ScrollView>
    </div>
  );
}

export default Payement;
