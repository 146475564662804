import React from "react";
import styles from "assets/styles.module.css";
import Text from "components/Text";
import { FaSchool } from "react-icons/fa";
import Avatar from "./Avatar";
import { addAppelOffline } from "redux/features/userSlice";

function AppelCard(props) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#fff",
        border: "1px solid rgba(0,0,0,0.15)",
        padding: 10,
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 10,
      }}
    >
      <div style={{ display: "flex" }}>
        <Avatar width={40} height={40} style={{ marginRight: 7 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text style={{ fontWeight: 700, color: "black", fontSize: 14 }}>
            {props?.student?.firstName} {props?.student?.lastName}
          </Text>
          <div className={styles.row} style={{ marginTop: 6, marginBottom: 4 }}>
            <FaSchool size={16} />
            <Text style={{ marginLeft: 5, fontSize: 12 }}>
              {" "}
              {props?.student?.school?.name}
            </Text>
          </div>
          <Text style={{ fontSize: 12 }}>{props?.student?.classe?.name}</Text>
        </div>
      </div>

      <div className={styles.row}>
        <Text
          style={{
            fontSize: 13,
            color:
              props.status === "PRESENT"
                ? "green"
                : props.status === "ABSENT"
                ? "red"
                : "orange",
          }}
        >
          {props.status.toLowerCase() ?? "none"}
        </Text>

        {!props.onlineData && props.status === "ABSENT" && (
          <button
            style={{
              fontSize: 12,
              backgroundColor: "orange",
              borderRadius: 20,
              padding: "5px 10px",
              marginLeft: 5,
              color: "#fff",
            }}
            onClick={() =>
              props.dispatch(
                addAppelOffline({
                  studentId: props?.studentId,
                  status: "RETARD",
                })
              )
            }
          >
            Retard
          </button>
        )}
        {/* <Button title={"Present"} style={{ width: 100 }} /> */}
      </div>
    </div>
  );
}

export default AppelCard;
