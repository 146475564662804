import SchollCard from "components/SchollCard";
import Text from "components/Text";
import styles from "assets/styles.module.css";
import { bgColorPrimary } from "constants";
import useGetUserInfo from "hooks/useGetUserInfo";
import React from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useGetCoachQuery } from "redux/features/coachApi";
import { addSchoolId } from "redux/features/userSlice";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocation, useNavigate } from "react-router-dom";
import { setAccess, setConnection } from "redux/features/connectionSlice";
import { useAddSessionMutation } from "redux/features/sessionApi";

function School() {
  const { userId, schoolId, csrfToken } = useGetUserInfo();

  const navigation = useNavigate();
  const loaction = useLocation();
  const from = loaction.state?.from?.pathname || "/appel";

  const { data: coach, isLoading: CoachLoading } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  const [addSession] = useAddSessionMutation();

  const dispatch = useDispatch();

  const handleSelectSchool = async (id, ecoleName) => {
    dispatch(addSchoolId(id));
    dispatch(setConnection(true));
    dispatch(setAccess(true));
    // navigation("/appel", { replace: true });
    navigation("/appel", { replace: true });

    /**
     * Aplitude
     */
    if (coach?.id) {
      try {
        await addSession({ token: csrfToken, body: { userId: coach?.id } });

        amplitude.track("Ecole", {
          telephone: coach?.phone,
          utilisateur: coach?.fullName,
          ecole: ecoleName,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div
      className={styles.row}
      style={{
        flexDirection: "column",
        paddingTop: 100,
        color: "rgba(0,0,0,0.7)",
        minHeight: "100dvh",
      }}
    >
      <h1 style={{ fontSize: 20, marginBottom: 30 }}>Choisir une école</h1>

      {CoachLoading ? (
        <TailSpin
          height="40"
          width="40"
          color={bgColorPrimary}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      ) : coach?.schools && coach?.schools.length > 0 ? (
        coach?.schools?.map((item) => (
          <SchollCard
            key={item?.id}
            {...item}
            onClick={() => handleSelectSchool(item?.id, item?.name)}
            active={schoolId === item?.id}
          />
        ))
      ) : (
        <Text
          style={{ fontSize: 22, fontWeight: 600, color: "rgba(0,0,0,0.3)" }}
        >
          Vous n'avez pas d'école
        </Text>
      )}
    </div>
  );
}

export default School;
