import React from "react";

function Avatar({
  width = null,
  height = null,
  style = {},
  containerStyle = {},
  src = null,
}) {
  return (
    <div
      style={{
        width: width ?? 50,
        height: height ?? 50,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 7,
        ...style,
      }}
    >
      <img
        src={src ?? "icons/avatar.png"}
        alt="user avatart"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          ...containerStyle,
        }}
      />
    </div>
  );
}

export default Avatar;
