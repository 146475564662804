import { ApiMananger } from "./apiMananger";

const comprehensionApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    // http://127.0.0.1:8000/api/v1/comprehensions/details/?coachId=4&ficheId=25&schoolId=1
    getComprehensionWithEleves: build.query({
      query: ({ coachId, schoolId, fileId }) =>
        `/comprehensions/details/?coachId=${coachId}&ficheId=${fileId}&schoolId=${schoolId}`,
      providesTags: ["comprehensions"],
    }),
    addComprehension: build.mutation({
      query({ body, token }) {
        return {
          url: "/comprehensions/",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["comprehensions"],
    }),
  }),
});

export const {
  useAddComprehensionMutation,
  useGetComprehensionWithElevesQuery,
} = comprehensionApi;
