import React from "react";
import { FaSchool } from "react-icons/fa";
import styles from "assets/styles.module.css";
import Text from "./Text";
import { bgColorPrimary } from "constants";

function SchollCard({ onClick, name, active }) {
  return (
    <div
      className={styles.row}
      onClick={onClick}
      style={{
        backgroundColor: active ? bgColorPrimary : "transparent",
        borderRadius: 10,
        border: "1px solid rgba(0,0,0,0.2)",
        margin: "5px 0",
        marginRight: 10,
        padding: 10,
        color: active ? "white" : "rgba(0,0,0,0.7)",
        cursor: "pointer",
      }}
    >
      <FaSchool style={{ marginRight: 10 }} size={30} />
      <Text style={{ fontSize: 15 }}>{name}</Text>
    </div>
  );
}

export default SchollCard;
