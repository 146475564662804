import React from "react";
import styles from "assets/styles.module.css";
import SearchBarPage from "components/SearchBarPage";
// import ScrollView from "components/ScrollView";
// import axios from "axios";
// import { useGetCoachAppelsQuery } from "redux/features/appelApi";

function Evaluation() {
  // const { csrfToken: token } = useGetCoachAppelsQuery();

  return (
    <div className={styles.evaluationContainer}>
      <SearchBarPage />

      {/* <ScrollView>Evaluation</ScrollView> */}
    </div>
  );
}

export default Evaluation;
