import React from "react";
import styles from "assets/styles.module.css";
import Logo from "./Logo";
import Text from "./Text";
import { FaRedoAlt, FaCog } from "react-icons/fa";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetCoachQuery } from "redux/features/coachApi";
import { useGetCoachAppelsQuery } from "redux/features/appelApi";
import { useGetElevesQuery } from "redux/features/eleveApi";
import { useGetCoachFichesQuery } from "redux/features/ficheApi";
import { TailSpin } from "react-loader-spinner";
import { DrawerContext } from "context/DrawerContext";
import { useDispatch } from "react-redux";
import { setAccess, setConnection } from "redux/features/connectionSlice";

function Header() {
  const { handleOpen } = React.useContext(DrawerContext);

  const { userId, schoolId } = useGetUserInfo();
  const { data: coachInfo, refetch: coach } = useGetCoachQuery(userId);
  const { refetch: eleves } = useGetElevesQuery({ coachId: userId, schoolId });
  const { refetch: fiches } = useGetCoachFichesQuery({
    coachId: userId,
    schoolId,
  });
  const { refetch: refreshAppel } = useGetCoachAppelsQuery({
    coachId: userId,
    schoolId,
  });

  const [pathName, setPathname] = React.useState("");
  const [refreching, setRefreching] = React.useState(false);

  // const { isOnline } = useConnection();

  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    const urlParts = window.location.href.split("/");
    setPathname(urlParts[3] === "" ? "Appel" : urlParts[3]);
    if (urlParts[3] === "school") {
      dispatch(setConnection(false));
    } else {
      dispatch(setAccess(true));
    }
  }, [window.location.href]);

  const handleRefetch = async () => {
    setRefreching(true);
    await Promise.all([
      coach(),
      schoolId && eleves(),
      schoolId && refreshAppel(),
      schoolId && fiches(),
    ]);
    setRefreching(false);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.row}>
        <Logo />
        <Text
          style={{
            fontSize: 20,
            fontWeight: 500,
            marginLeft: 10,
            textTransform: "capitalize",
          }}
        >
          {pathName}
        </Text>
      </div>

      <Text style={{ fontSize: 14, color: "#fff", fontWeight: 700 }}>
        {coachInfo?.fullName.slice(0, 20)}
      </Text>

      <div className={styles.row}>
        {/* <Text>{connectionSpeed}</Text> */}
        {/* <FaWifi
          style={{ margin: "0 10px" }}
          size={20}
          color={isOnline ? "green" : "red"}
        /> */}

        {refreching ? (
          <TailSpin
            height="18"
            width="18"
            color={"#fff"}
            ariaLabel="tail-spin-loading"
            radius="4"
            wrapperStyle={{ margin: "0 10px" }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <FaRedoAlt
            style={{ margin: "0 10px" }}
            size={17}
            onClick={handleRefetch}
          />
        )}

        <FaCog style={{ margin: "0 10px" }} size={18} onClick={handleOpen} />
      </div>
    </div>
  );
}

export default Header;
