import React from "react";
import styles from "assets/styles.module.css";
import Text from "./Text";

function Badge({ title }) {
  return (
    <div className={styles.badge} style={{}}>
      <Text style={{fontSize: 9, fontWeight: 700,}}>{title}</Text>
    </div>
  );
}

export default Badge;
