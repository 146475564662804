import styles from "assets/styles.module.css";
import React from "react";
import Text from "./Text";

function Empty({ title }) {
  return (
    <div className={styles.center}>
      <Text>{title}</Text>
    </div>
  );
}

export default Empty;
