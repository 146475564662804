import styles from "assets/styles.module.css";
import React from "react";
import Text from "./Text";

function Offline() {
  return (
    <div className={styles.center}>
      <Text>Vous n'avez pas de connexion</Text>
    </div>
  );
}

export default Offline;
