import React from "react";
import styles from "assets/styles.module.css";
import { FaSchool } from "react-icons/fa";
import Text from "./Text";
import Button from "./Button";
import ReactWhatsapp from "react-whatsapp";
import {
  IconBrandWhatsapp,
  IconMessage2Share,
  IconPhoneOutgoing,
  IconPhonePlus,
  IconX,
} from "@tabler/icons-react";
import { bgColorPrimary } from "constants";
import { IconDotsVertical } from "@tabler/icons-react";
import Slide from "./Slide";

function StudentCard(props) {
  const [show, onShow] = React.useState(false);
  return (
    <Slide active={props?.active}>
      <div
        style={{ minWidth: props?.width, padding: "0 10px", paddingTop: 20 }}
      >
        {props?.status === "end" ? (
          <>
            <Text
              style={{
                fontSize: 22,
                fontWeight: 500,
                marginBottom: 10,
                // textAlign: "center",
              }}
            >
              Bravo! <br /> Vous venez de terminer l'appel
            </Text>

            <div style={{ display: "flex", width: "100%", gap: 10 }}>
              {/* <Button
              title={"Envyer les appels"}
              bgColor={"green"}
              color={"#fff"}
              style={{ marginTop: 20 }}
              onClick={props.handleAddAppel}
              disabled={props.onAppelLoading}
              loading={props.onAppelLoading}
            /> */}
              <Button
                title={"Voir les appels"}
                // bgColor={"green"}
                // color={"#fff"}
                style={{ marginTop: 10 }}
                onClick={props?.onfinishedAppel}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.studentCardContainer}>
              <div className={styles.studentCardEcole}>
                <div className={styles.row}>
                  <FaSchool />
                  <Text style={{ fontSize: 12, marginLeft: 5 }}>
                    {props?.schoolId?.name}
                  </Text>
                </div>
                <Text style={{ marginTop: 3 }}>
                  Classe: {props?.classeId?.name}
                </Text>
              </div>

              <div
                style={{ position: "absolute", top: 0, right: 0, padding: 7 }}
                onClick={() =>
                  props.navigation(
                    `student/${props?.id}/${
                      props?.payements?.status ? "OUI" : "NON"
                    }`
                  )
                }
              >
                <IconDotsVertical />
              </div>

              <div className={styles.studentCardAvatar}>
                <img
                  src="./icons/avatar.png"
                  alt="user avatar"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>

              <div
                className={styles.row}
                style={{
                  marginTop: 10,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ color: "rgba(0,0,0,0.8)", fontSize: 17 }}>
                  {props?.firstName} {props?.lastName}
                </Text>

                <Text
                  style={{
                    fontSize: 13,
                    color:
                      props?.appel?.status ||
                      props?.appelOffline?.find(
                        (appel) => appel?.studentId === props?.id
                      )
                        ? "#fff"
                        : "rgba(0,0,0,0.5)",
                    backgroundColor:
                      props?.appel?.status === "PRESENT" ||
                      props?.appelOffline?.find(
                        (appel) => appel?.studentId === props?.id
                      )?.status === "PRESENT"
                        ? "green"
                        : props?.appel?.status === "ABSENT" ||
                          props?.appelOffline?.find(
                            (appel) => appel?.studentId === props?.id
                          )?.status === "ABSENT"
                        ? "red"
                        : props?.appel?.status === "RETARD" ||
                          props?.appelOffline?.find(
                            (appel) => appel?.studentId === props?.id
                          )?.status === "RETARD"
                        ? "orange"
                        : "transparent",
                    borderRadius: 20,
                    padding: "5px 10px",
                  }}
                >
                  {/* {props?.appel?.status ?? "Non appeler"} */}
                  {props?.appel?.status
                    ? props?.appel?.status
                    : props?.appelOffline?.find(
                        (appel) => appel?.studentId === props?.id
                      )
                    ? props?.appelOffline?.find(
                        (appel) => appel?.studentId === props?.id
                      ).status
                    : "Non_appeler"}
                </Text>
              </div>
              <div
                style={{
                  width: "100%",
                  color: "gray",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                {props?.payements?.status ? (
                  <div className={styles.row}>
                    <Text
                      style={{
                        border: "1px solid green",
                        borderRadius: 20,
                        padding: "8px 10px",
                        color: "green",
                        // marginTop: 10,
                        fontSize: 13,
                      }}
                    >
                      Voir le paiement
                    </Text>
                  </div>
                ) : (
                  <Button
                    style={{
                      // marginTop: 10,
                      borderRadius: 20,
                      height: 30,
                      width: 100,
                    }}
                    title={"Payer"}
                    onClick={() =>
                      props.navigation(
                        "payement/" +
                          props?.id +
                          "/" +
                          props?.firstName +
                          " " +
                          props?.lastName
                      )
                    }
                  />
                )}

                <div
                  style={{
                    all: "unset",
                    padding: 5,
                    borderRadius: 20,
                    height: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "1px solid " + bgColorPrimary,
                    color: bgColorPrimary,
                    paddingRight: 10,
                  }}
                  onClick={() => onShow(true)}
                >
                  <Text
                    style={{ fontSize: 13, marginRight: 10, marginLeft: 5 }}
                  >
                    Contacter parent
                  </Text>
                  <IconPhonePlus size={18} />
                </div>
              </div>

              <div
                className={show ? styles.studentOverlay : styles.hidden}
                onClick={() => onShow(false)}
              >
                {props?.telephoneParent ? (
                  <ReactWhatsapp
                    number={props?.telephoneParent || ""}
                    message={`Bonjour cher parent, l'eleve  ${
                      props?.firstName + " " + props?.lastName
                    }, n'a pas envore payer pour ce moi ci. \n Pour payer veuillez cliquer ici : \n https://genimi-admin.bakeli.tech/payements/wave/`}
                    className={styles.studentOverlayIcon}
                    style={{
                      outline: "none",
                      border: "none",
                      color: "#fff",
                      marginBottom: 10,
                    }}
                  >
                    <IconBrandWhatsapp size={18} />
                  </ReactWhatsapp>
                ) : (
                  "pas de numero"
                )}
                <a
                  className={styles.studentOverlayIcon}
                  style={{ marginBottom: 10, color: "#fff" }}
                  href={`tel:${props?.telephoneParent}`}
                >
                  <IconPhoneOutgoing size={18} color={"#fff"} />
                </a>
                <a
                  className={styles.studentOverlayIcon}
                  style={{ marginBottom: 10, color: "#fff" }}
                  href={`sms:${props?.telephoneParent}`}
                >
                  <IconMessage2Share size={18} color={"#fff"} />
                </a>
                <div
                  className={styles.studentOverlayIcon}
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => onShow(false)}
                >
                  <IconX size={18} />
                </div>
              </div>
            </div>

            <div className={styles.row} style={{ marginTop: 10 }}>
              <Button
                title={"Present"}
                bgColor={"green"}
                color={"#fff"}
                style={{ marginRight: 10 }}
                radius={20}
                disabled={
                  props?.appel?.status ||
                  props?.appelOffline?.find(
                    (appel) => appel?.studentId === props?.id
                  )?.status === "PRESENT"
                    ? true
                    : false
                }
                onClick={() => {
                  if (props?.appel?.status) {
                    props?.onCallUpdate("PRESENT");
                  } else {
                    props?.onCall("PRESENT");
                  }
                }}
              />
              <Button
                title={"Absent"}
                bgColor={"red"}
                color={"#fff"}
                style={{ marginLeft: 10 }}
                radius={20}
                disabled={
                  props?.appel?.status ||
                  props?.appelOffline?.find(
                    (appel) => appel?.studentId === props?.id
                  )?.status === "ABSENT"
                    ? true
                    : false
                }
                onClick={() => {
                  if (props?.appel?.status) {
                    props?.onCallUpdate("PRESENT");
                  } else {
                    props?.onCall("ABSENT");
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </Slide>
  );
}

export default StudentCard;
