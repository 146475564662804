import { useState, useEffect } from "react";

function useConnection() {
  const [isOnline, setIsOnline] = useState(true);
  const [connectionSpeed, setConnectionSpeed] = useState(null);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    if (navigator.connection) {
      const connection = navigator.connection;
      setConnectionSpeed(connection.downlink);
      connection.addEventListener("change", handleConnectionChange);
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      if (navigator.connection) {
        navigator.connection.removeEventListener(
          "change",
          handleConnectionChange
        );
      }
    };
  }, []);

//   useEffect(() => {
//     if (connectionSpeed) {
//       const quality = calculateConnectionQuality(connectionSpeed);
//       setConnectionQuality(quality);
//     }
//   }, [connectionSpeed]);

  const handleConnectionChange = () => {
    const connection = navigator.connection;
    setConnectionSpeed(connection.downlink);
  };

  const handleOnline = (e) => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  return { isOnline, connectionSpeed };
}

export default useConnection;
