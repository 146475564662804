import React from "react";
import styles from "assets/styles.module.css";
import Text from "./Text";
import { NavLink } from "react-router-dom";

function TabLink({ label, Icon, path, style, onclick }) {
  return onclick ? (
    <div
      to={path}
      // className={styles.tabContainer}
      className={styles.tabContainer}
      style={{ ...style }}
      onClick={onclick}
    >
      <Icon />
      <Text style={{ marginTop: 5, fontSize: 12 }}>{label}</Text>
    </div>
  ) : (
    <NavLink
      to={path}
      // className={styles.tabContainer}
      className={({ isActive, isPending }) =>
        isActive ? styles.tabContainerActive : styles.tabContainer
      }
      style={{ ...style }}
    >
      <Icon />
      <Text style={{ marginTop: 5, fontSize: 12 }}>{label}</Text>
    </NavLink>
  );
}

export default TabLink;
