import { useSelector } from "react-redux";
import { getConnectionStatus } from "redux/features/connectionSlice";
import { getCsrfToken, getSchollId, getUserId } from "redux/features/userSlice";

function useGetUserInfo() {
  const userId = useSelector(getUserId);
  const schoolId = useSelector(getSchollId);
  const csrfToken = useSelector(getCsrfToken);
  const connection = useSelector(getConnectionStatus);

  return { userId, schoolId, csrfToken, connection };
}

export default useGetUserInfo;
