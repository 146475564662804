import React from "react";
import styles from "assets/styles.module.css";
import { Outlet } from "react-router-dom";

function CorpusContainer() {
  return (
    <div className={styles.evaluationContainer}>
      <Outlet />
    </div>
  );
}

export default CorpusContainer;
