import React from "react";
import styles from "assets/styles.module.css";
import { Outlet } from "react-router-dom";
// import amplitude from "amplitude-js";
// import useGetUserInfo from "hooks/useGetUserInfo";
// import { useGetCoachQuery } from "redux/features/coachApi";

function Guide() {
  // const { userId } = useGetUserInfo();

  // const { data: coach } = useGetCoachQuery(userId, {
  //   refetchOnFocus: true,
  // });

  /**
   * Aplitude
   */
  // if (coach?.id) {
  //   amplitude.getInstance().logEvent("session", {
  //     coach: {
  //       prenom_et_nom: coach?.fullName,
  //       telephone: coach?.phone,
  //     },
  //     page: "Guide",
  //   });
  // }

  return (
    <div className={styles.evaluationContainer}>
      <Outlet />
    </div>
  );
}

export default Guide;
