import React from "react";

export const DrawerContext = React.createContext();

export const DrawerProvider = ({ children }) => {
  const [opened, setOpened] = React.useState(false);

  const handleOpen = () => setOpened(true);

  const handleClose = () => setOpened(false);

  return (
    <DrawerContext.Provider value={{ handleClose, handleOpen, opened }}>
      {children}
    </DrawerContext.Provider>
  );
};
