import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoutes, privateRoutes } from "routes/app.routes";
import RequireAuth from "components/RequireAuth";
import useGetUserInfo from "hooks/useGetUserInfo";
import useHotjar from "react-use-hotjar";

function Root() {
  const { userId, schoolId } = useGetUserInfo();

  const { identifyHotjar, initHotjar, stateChange } = useHotjar();

  React.useEffect(() => {
    initHotjar("3504318", 6);
    identifyHotjar(`${userId}`, { name: "Abou " + userId });
    // console.log({ user });
  }, [identifyHotjar, initHotjar, stateChange, userId]);

  return (
    <Routes>
      {publicRoutes.map((item, index) => (
        <Route
          path={item?.path}
          element={userId ? <Navigate to={"/school"} /> : item?.element}
          key={index}
        />
      ))}

      <Route element={<RequireAuth />}>
        {privateRoutes.map((item, index) =>
          item?.subPath ? (
            <Route
              path={item?.path}
              element={schoolId ? item?.element : <Navigate to={"/school"} />}
              key={index}
            >
              {item?.subPath.map((subItem, subIndex) => (
                <Route
                  path={subItem?.path}
                  element={subItem?.element}
                  key={subIndex}
                />
              ))}
            </Route>
          ) : (
            <Route path={item?.path} element={item?.element} key={index} />
          )
        )}
      </Route>
    </Routes>
  );
}

export default Root;
