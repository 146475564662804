const Slide = ({ children, active }) => {
  return (
    <div
      className={`slide ${active ? "active" : ""}`}
      style={{ height: "100vh" }}
    >
      {children}
    </div>
  );
};

export default Slide;
