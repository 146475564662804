import { dividerColor } from "constants";
import React from "react";

function Divider({ height, style, color, py }) {
  return (
    <div
      style={{
        width: "100%",
        height: height ?? 1,
        backgroundColor: color ?? dividerColor,
        marginTop: `${py}px`,
        marginBottom: `${py}px`,
        ...style,
      }}
    ></div>
  );
}

export default Divider;
