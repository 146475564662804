import { bgColorPrimary } from "constants";
import styles from "assets/styles.module.css";
import React from "react";
import { TailSpin } from "react-loader-spinner";

function Loader() {
  return (
    <div
      // style={{
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   height: "100%",
      //   width: "100%",
      // }}
      className={styles.center}
    >
      <TailSpin
        height="60"
        width="60"
        color={bgColorPrimary}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}

export default Loader;
