import React from "react";
import styles from "assets/styles.module.css";
import { IconListSearch } from "@tabler/icons-react";

function SearchBarPage({ onSearch, style }) {
  return (
    <div className={styles.headerPage} style={{ ...style }}>
      <div className={styles.searchContainer}>
        <IconListSearch size={20} color={"rgba(0,0,0,0.6)"} />
        <input
          type={"text"}
          placeholder={"Recherche"}
          style={{ fontSize: 14 }}
          className={styles.inputSearch}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
    </div>
  );
}

export default SearchBarPage;
