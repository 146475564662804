import React, { useEffect, useState } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { persistor, store } from "redux/app/Store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "context/AuthContext";
import Root from "Root";
import { BrowserRouter } from "react-router-dom";
import { DrawerProvider } from "context/DrawerContext";
import ModalInstall from "components/ModalInstall";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleInstall = () => {
    setOpened(false);

    // Show the prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        console.log({ choiceResult });
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    // btnInstall.current.style.display = "none";
    // console.log(btnInstall.current);
    async function actionInstall(e) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      setOpened(true);
      // Update UI to notify the user they can add to home screen
      // btnInstall.current.style.display = "block";
    }

    window.addEventListener("beforeinstallprompt", actionInstall);
    // return window.removeEventListener("beforeinstallprompt", actionInstall);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <DrawerProvider>
              <div style={{ minWidth: 300 }}>
                <header className="App-header">
                  <Root />
                  <ModalInstall
                    opened={opened}
                    onInstall={handleInstall}
                    onClosed={() => setOpened(false)}
                  />
                </header>
              </div>
            </DrawerProvider>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
