import * as amplitude from "@amplitude/analytics-browser";
import Logo from "components/Logo";
import Text from "components/Text";
import { bgColorPrimary } from "constants";
import { AuthContext } from "context/AuthContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import Form from "components/Form";
import { NUMBER_VERIFIER_PATH } from "routes/api.paths";
import axios from "axios";
import { RADIUS } from "constants";

function Login() {
  const { login, isLoginLoading } = React.useContext(AuthContext);

  const navigation = useNavigate();

  const [existingUser, setExistingUser] = React.useState(false);
  const [verifyNumberLoading, setVerifyNumberLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleLogin = async (value) => {
    if (!existingUser) {
      try {
        setVerifyNumberLoading(true);
        const { data } = await axios.get(
          NUMBER_VERIFIER_PATH + value?.phone + "/"
        );
        // console.log({ data });
        if (data) {
          setExistingUser(true);
        }
        setVerifyNumberLoading(false);
      } catch (error) {
        console.log({ error });
        if (error?.response?.status === 404) {
          // navigation("/register/" + value?.phone);
          setError({ ...error, phone: "this user is not registered" });
          setTimeout(() => {
            setError(null);
          }, 3000);
        } else {
          setError({ ...error, phone: "Une erreur c'est produite " });
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
        setVerifyNumberLoading(false);
      }
      return;
    }

    if (existingUser) {
      const user = await login(value);
      if (!user.error) {
        if (user?.data?.phone && user?.data?.fullName) {
          amplitude.track("Login", {
            telephone: user?.data?.phone,
            utilisateur: user?.data?.fullName,
          });
        }
      } else {
        setError({ ...error, password: "Le mot de passe est incorrect" });
      }
    }
  };

  const customValidations = {
    phone: (value) =>
      value.length !== 9 &&
      !value.match(/^(221|00221|\+221)?(77|78|75|70|76)[0-9]{7}$/gm)
        ? "Numero de telephone invalid"
        : null,
    password: (value) => !value && "Ce champs est obligatoire",
  };

  return (
    <div style={{ width: "100%", height: "100vh", color: "rgba(0,0,0,0.7)" }}>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          flexDirection: "column",
          height: "calc(100% / 2)",
          justifyContent: "flex-end",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          src="./icons/genimi/loginImage.jpg"
          alt="loginImage"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            // left: "-5%",
          }}
        />
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: 10,
          }}
        >
          <div style={{ position: "absolute", top: 10 }}>
            <Logo blue width={70} height={70} />
          </div>
          {/* <Text
            style={{
              marginBottom: 30,
              marginTop: 10,
              fontSize: 25,
              fontWeight: 700,
            }}
          >
            Bonjour , Bienvenue sur
          </Text> */}

          <Text
            style={{
              marginBottom: 0,
              fontSize: 14,
              fontWeight: 500,
              wordSpacing: 5,
              letterSpacing: 1,
            }}
          >
            Première plateforme mobile de tutorat en Afrique francophone.
          </Text>
        </div>
      </div>

      <Form
        onSubmit={handleLogin}
        validations={customValidations}
        style={{ padding: 10 }}
      >
        <Form.Group
          name={"phone"}
          label={"Telephone"}
          error={error?.phone ?? null}
          defaultValue={"lalal"}
          style={{
            border: 0,
            borderBottom: "2px solid gray",
          }}
        >
          <Form.Input
            type={"text"}
            placeholder={"🇸🇳 +221 77 *** ** **"}
            required
            disabled={existingUser}
            maxLength={9}
            // style={{ borderRadius: RADIUS }}
            radius={0}
          />
        </Form.Group>

        {existingUser && (
          <Form.Group
            name={"password"}
            label={"Mot de passe"}
            error={error?.password ?? null}
            style={{
              border: 0,
              borderBottom: "2px solid gray",
            }}
          >
            <Form.Input
              type={"password"}
              placeholder={"**********"}
              required
              // style={{ borderRadius: RADIUS }}
              radius={0}
            />
          </Form.Group>
        )}

        {existingUser && (
          <Text
            style={{
              textAlign: "right",
              margin: "20px 0",
              cursor: "pointer",
            }}
            onClick={() => navigation("/forgetPassword")}
          >
            Mot de passe oublie
          </Text>
        )}

        <Form.Button
          title={<Text style={{}}>Se connecter</Text>}
          fullidth
          style={{
            backgroundColor: bgColorPrimary,
            color: "#fff",
            fontWeight: 700,
            fontSize: 18,
            borderRadius: RADIUS,
          }}
          loading={isLoginLoading || verifyNumberLoading}
        />
      </Form>

      {/* <a
        style={{
          textAlign: "right",
          margin: "20px 0",
          cursor: "pointer",
        }}
        href={"tel:+221775858920"}
      >
        Mot de passe oublie
      </a>
      <a href="sms:+221775858920">Cliquez ici pour envoyer un SMS</a> */}
    </div>
  );
}

export default Login;
