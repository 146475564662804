import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import * as amplitude from "@amplitude/analytics-browser";
import useGetUserInfo from "hooks/useGetUserInfo";
import { useGetCoachQuery } from "redux/features/coachApi";
import Text from "components/Text";

function Logout({ handleLogout }) {
  const { logout } = useContext(AuthContext);
  const { userId } = useGetUserInfo();

  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });

  return (
    <div
      onClick={async () => {
        await logout();
        amplitude.track("Logout", {
          telephone: coach?.phone,
          utilisateur: coach?.fullName,
        });
        handleLogout();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: 15,
        color: "red",
        fontWeight: 500
      }}
    >
      <FaSignOutAlt style={{ marginRight: 10 }} size={18} />
      <Text>Se deconnecter</Text>
    </div>
  );
}

export default Logout;
