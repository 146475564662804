import { EVALUATION_API, FICHE_API } from "../../routes/api.paths";
import { ApiMananger } from "./apiMananger";

const evaluationApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getEvaluation: build.query({
      query: ({ ficheId, schoolId }) =>
        `${EVALUATION_API}/?ficheId=${ficheId}&schoolId=${schoolId}`,
      providesTags: ["Evaluations"],
    }),
    updateEvalution: build.mutation({
      query({ data, token }) {
        const { evaluation, ...body } = data;
        return {
          url: `${EVALUATION_API}/${evaluation}/`,
          method: "PUT",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Evaluations", "Fiches"],
    }),
    addEvalution: build.mutation({
      query({ body, token }) {
        return {
          url: FICHE_API + "/evaluations/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Evaluations", "Fiches"],
    }),
  }),
});

export const {
  useGetEvaluationQuery,
  useUpdateEvalutionMutation,
  useAddEvalutionMutation,
} = evaluationApi;
