import Form from "components/Form";
import Text from "components/Text";
import { bgColorPrimary } from "constants";
import { AuthContext } from "context/AuthContext";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

function Register() {
  const navigation = useNavigate();
  const params = useParams();

  const { register, isRegisterLoading } = React.useContext(AuthContext);

  const handleRegister = async (values) => {
    const { confirmPassword, ...form } = values;
    await register({ ...form, phone: params?.phone });
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "20px 10px",
        color: "rgba(0,0,0,0.7)",
        height: "100vh",
      }}
    >
      <FaArrowLeft onClick={() => navigation(-1)} size={25} />

      <Text
        style={{
          marginBottom: 40,
          marginTop: 20,
          fontSize: 25,
          fontWeight: 700,
        }}
      >
        Inscrivez vous
      </Text>

      <Form onSubmit={handleRegister}>
        <Form.Group name={"phone"} label={"Telephone"}>
          <Form.Input
            placeholder={params?.phone ?? "77 *** ** **"}
            value={params?.phone}
            disabled={params?.phone}
          />
        </Form.Group>
        <Form.Group name={"fullName"} label={"Prenom et nom"}>
          <Form.Input placeholder={"Jean Paul"} />
        </Form.Group>
        <Form.Group name={"password"} label={"Mot de passe"}>
          <Form.Input type={"password"} placeholder={"**********"} />
        </Form.Group>
        <Form.Group name={"confirmPassword"} label={"Confirm mot de passe"}>
          <Form.Input type={"password"} placeholder={"**********"} />
        </Form.Group>

        <Form.Button
          title={"S'inscrire"}
          fullidth
          style={{
            backgroundColor: bgColorPrimary,
            color: "#fff",
            fontWeight: 700,
            fontSize: 18,
          }}
          loading={isRegisterLoading}
        />
      </Form>
      {/* <Text onClick={() => navigation("/login")}>Connectez-vous</Text> */}
    </div>
  );
}

export default Register;
