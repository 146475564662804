import StudentCard from "components/StudentCard";
import styles from "assets/styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import Loader from "components/Loader";
import Empty from "components/Empty";
import Offline from "components/Offline";
// import { TabsContext } from "context/TabContext";

function Students(props) {
  // const ref = React.useRef(null);

  // // const [index, setIndex] = React.useState(0);
  // const [currentItem, setCurrentItem] = React.useState(0);

  // // const { updateActiveTab } = React.useContext(TabsContext);

  // React.useEffect(() => {
  //   ref.current?.children[currentItem]?.scrollIntoView({ behavior: "smooth" });
  // }, [currentItem]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [active, setActive] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const carousel = carouselRef.current;
    carousel?.addEventListener("wheel", handleScroll);

    return () => {
      carousel?.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    const scrollAmount = event.deltaY;
    const slideWidth = carouselRef.current.clientWidth;
    const halfScreenWidth = slideWidth / 2;
    // console.log("lalla", scrollAmount, halfScreenWidth, slideWidth);
    if (scrollAmount > 0) {
      if (scrollAmount >= halfScreenWidth) {
        // console.log("next", scrollAmount, halfScreenWidth);
        goToNextSlide();
      } else {
        // console.log("prev", scrollAmount, halfScreenWidth);
        goToPrevSlide();
      }
      // } else {
      //   // if (Math.abs(scrollAmount) >= halfScreenWidth) {
      //   //   goToPrevSlide();
      //   // } else {
      //   //   goToNextSlide();
      //   // }
    }
  };

  const data = props?.data;

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  return props.isLoading ? (
    <Loader />
  ) : (
    <div
      className={styles.appelContent}
      // style={{
      //   display: "flex",
      //   flexWrap: "nowrap",
      //   overflowX: "auto",
      // }}
      // ref={ref}
      style={{ overflowY: "scroll" }}
    >
      <div className="carousel" ref={carouselRef}>
        {props?.data && props?.data.length > 0 ? (
          [
            ...props?.data,
            props.offlineData.length > 0 && {
              status: "end",
              id: "zzzzzzzzzzzzzzzzzzzzz",
            },
          ]?.map(
            (student, index) =>
              student.id && (
                <StudentCard
                  active={index === currentIndex}
                  key={student?.id}
                  index={index}
                  width={window?.innerWidth}
                  onCall={(status) => {
                    goToNextSlide();
                    carouselRef.current.scroll({
                      left: (index + 1) * carouselRef.current.clientWidth,
                      // behavior: "smooth",
                    });
                    console.log(
                      "left: " + (index + 1) * carouselRef.current.clientWidth
                    );
                    props?.handleCall(student, status);
                  }}
                  onCallUpdate={(status) => {
                    // goToNextSlide();
                    carouselRef.current?.scrollTo({
                      left: 400,
                      behavior: "smooth",
                    });
                    // setActive(index);
                    console.log(carouselRef.current);
                    props?.handleCallUpdate(student, status);
                  }}
                  appelOffline={props?.offlineData}
                  navigation={props?.navigation}
                  onfinishedAppel={props.onfinishedAppel}
                  handleAddAppel={props.onSendAppel}
                  onAppelLoading={props.onAppelLoading}
                  {...student}
                />
              )
          )
        ) : !props?.isOnline ? (
          <Offline />
        ) : (
          <Empty title={"Vous n'avez pas d'eleve"} />
        )}
      </div>
    </div>
  );
}

export default Students;
