import Text from "components/Text";
import { bgColorPrimary } from "constants";
import React from "react";
import { useNavigate } from "react-router-dom";

function CorpusPage() {
  const navigation = useNavigate();

  return (
    <div style={{ height: "100vh" }}>
      <div
        className="image"
        style={{
          height: 300,
          // backgroundColor: "red",
          display: "grid",
          placeItems: "center",
        }}
      >
        <img
          src="diagnostique.png"
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "scale(.7)",
          }}
        />
      </div>
      <div
        className="info"
        style={{
          padding: "1rem",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          marginTop: 20,
        }}
      >
        <Text style={{}}>Générer des corpus pour les cours.</Text>
      </div>
      <div
        className=""
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <button
          style={{
            all: "unset",
            padding: "5px 20px",
            backgroundColor: bgColorPrimary,
            borderRadius: 20,
            color: "#fff",
            marginTop: 20,
          }}
          onClick={() => navigation("nouveaux")}
        >
          <Text>Générer</Text>
        </button>
      </div>
    </div>
  );
}

export default CorpusPage;
