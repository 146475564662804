export const ColorPrimary = "red";

// export const bgColorPrimary = "#027AFC";
export const bgColorPrimary = "#003B7F";
export const bgColorSecondary = "#027AFC";
// export const bgColor = "#003B7F";
// 027AFC
export const errorColor = "#B3261E"

export const dividerColor = "rgba(0, 0, 0, 0.12)"

export const RADIUS = 20;
