import Button from "components/Button";
import styles from "assets/styles.module.css";
import Loader from "components/Loader";
import PageHeader from "components/PageHeader";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFicheQuery } from "redux/features/ficheApi";
import Text from "components/Text";
import Divider from "components/Divider";
// import * as amplitude from "@amplitude/analytics-browser";
// import useGetUserInfo from "hooks/useGetUserInfo";
// import { useGetCoachQuery } from "redux/features/coachApi";

function DetailFiche() {
  const params = useParams();
  // const { userId, schoolId } = useGetUserInfo();

  // const { data: coach } = useGetCoachQuery(userId, {
  //   refetchOnFocus: true,
  // });

  const navigation = useNavigate();

  const { data, isLoading } = useGetFicheQuery(params.id);

  // let sessionStartTime = Date.now();

  // console.log(data);

  // if (data.data) {
  //   amplitude.track("lecture fiche", {
  //     coach: {
  //       prenom_et_nom: coach?.fullName,
  //       telephone: coach?.phone,
  //     },
  //     fiche: data?.data?.name,
  //     ecoleNumero: schoolId,
  //     session_start_time: sessionStartTime,
  //   });

  //   // Enregistrer un événement de ping toutes les X secondes.
  //   setInterval(() => {
  //     amplitude.track("lecture fiche", {
  //       coach: {
  //         prenom_et_nom: coach?.fullName,
  //         telephone: coach?.phone,
  //       },
  //       fiche: data?.data?.name,
  //       session_start_time: sessionStartTime,
  //     });
  //   }, 60000); // Envoi un événement de ping toutes les 1 minute.
  // }

  // Enregistrer un événement de fin de session.
  // window.addEventListener("beforeunload", () => {
  //   amplitude.logEvent("session_end", {
  //     session_duration: Date.now() - sessionStartTime,
  //   });
  // });

  return (
    <div
      className={styles.ficheContent}
      style={{
        color: "rgba(0,0,0,0.7)",
        overflowY: "scroll",
        paddingBottom: 100,
      }}
    >
      <PageHeader title={"Detail fiche"} navigation={navigation} />
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ padding: 10 }}>
          <div className={styles.row}>
            <Text style={{ fontSize: 30, fontWeight: 700 }}>*</Text>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
                textDecoration: "underline",
                marginBottom: 10,
                marginLeft: 5,
              }}
            >
              {params.materiel}
            </Text>
          </div>
          <Text
            style={{
              fontSize: 14,
              fontWeight: 400,
              marginBottom: 35,
            }}
          >
            {params.objectifPedagogique}
          </Text>

          <Divider style={{ margin: "10px 0" }} />

          <div
            dangerouslySetInnerHTML={{ __html: data?.data.contenu }}
            // dangerouslySetInnerHTML={{ __html: data?.data.html }}
            style={{ fontSize: 13, lineHeight: 1.7 }}
          />
          {/* <div>{data.data.contenu}</div> */}

          <Divider style={{ margin: "20px 0" }} />

          <Button
            style={{ margin: "10px 0" }}
            bgColor={"green"}
            color={"#fff"}
            title={"Evaluer la fiche"}
            onClick={() =>
              navigation(
                `/guide/ficheEvaluation/${params.materiel}/${params?.objectifPedagogique}/${params.id}`
              )
            }
          />
        </div>
      )}
    </div>
  );
}

export default DetailFiche;
