import { APPEL_API } from "../../routes/api.paths";
import { ApiMananger } from "./apiMananger";

const appelApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getCoachAppels: build.query({
      query: (id) =>
        APPEL_API + "/coach/school/" + id.coachId + "/" + id.schoolId + "/",
      providesTags: ["Appels", "comprehensions"],
    }),
    addAppel: build.mutation({
      query({ body, token }) {
        return {
          url: APPEL_API + "/",
          method: "POST",
          headers: {
            "X-CSRFToken": token,
          },
          body,
        };
      },
      invalidatesTags: ["Appels", "Eleves", "comprehensions"],
    }),
    updateAppel: build.mutation({
      query({ data, token }) {
        const { id, ...body } = data;
        return {
          url: `${APPEL_API}/${id}/`,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRFToken": token,
            Referer: "https://genimi-admin.bakeli.tech/",
            "Referrer-Policy": "strict-origin-when-cross-origin",
          },
          body,
        };
      },
      invalidatesTags: ["Appels", "Eleves"],
    }),
  }),
});

export const {
  useGetCoachAppelsQuery,
  useAddAppelMutation,
  useUpdateAppelMutation,
} = appelApi;
