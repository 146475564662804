import PageHeader from "components/PageHeader";
import Text from "components/Text";
import styles from "assets/styles.module.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetCorpusQuery } from "redux/features/coupusApi";
import { bgColorPrimary } from "constants";

function CorpusNouveaux() {
  const navigation = useNavigate();

  const {
    data: corpusData,
    isLoading,
    isError,
  } = useGetCorpusQuery({ limit: 10, offset: 0 });

  //   console.log({ corpus: corpusData?.data[0]?.exercices[0]?.split("Exercice") });

  return (
    <div
      className={styles.ficheContent}
      // style={{ height: "100%", width: "100%" }}
      style={{
        color: "rgba(0,0,0,0.7)",
        overflowY: "scroll",
        paddingBottom: 100,
        backgroundColor: "#fff",
      }}
    >
      <PageHeader title={"Liste des corpus générés "} navigation={navigation} />
      {isLoading
        ? "Chargement des donnees"
        : isError
        ? "une Erreure c'est produite"
        : corpusData?.data.length > 0
        ? corpusData?.data.map((corpus) => (
            <div
              key={corpus.id}
              style={{
                // backgroundColor: "red",
                padding: ".5rem",
                margin: ".5rem, 0",
                borderBottom: "1px solid gray",
                borderLeft: "5px solid " + bgColorPrimary,
                marginBottom: 30,
                backgroundColor: "rgba(0,0,0,0.04)",
              }}
            >
              <Text style={{ fontSize: "1.2rem", fontWeight: 700 }}>
                {corpus?.sujet}
              </Text>
              <div>
                {corpus?.exercices.length > 0
                  ? corpus?.exercices.map((exercice, _index) => (
                      <div
                        style={{ margin: "10px 0 ", marginLeft: 10 }}
                        key={_index}
                      >
                        {exercice.split("Exercice").map((question, index) => (
                          <div key={index + _index} style={{ marginTop: 0 }}>
                            {question === "" ? (
                              <Text
                                style={{ fontSize: "1.2rem", fontWeight: 700 }}
                              >
                                {"Excercie"} {_index + 1}
                              </Text>
                            ) : (
                              <Text style={{ marginLeft: 10, marginTop: 5 }}>
                                {question}
                              </Text>
                            )}
                          </div>
                        ))}
                      </div>
                    ))
                  : "Pas d'exercices"}
              </div>
            </div>
          ))
        : "corpus vide"}
    </div>
  );
}

export default CorpusNouveaux;
