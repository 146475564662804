import React from "react";
import styles from "../assets/styles.module.css";

function HacFlex({
  children,
  style,
  position,
  gap,
  direction,
  align,
  wrap = "wrap",
  mb,
  mt,
  ml,
  mr,
  mx,
  my,
  w,
  h,
  bg,
}) {
  return (
    <div
      style={{
        ...style,
        justifyContent:
          position === "center"
            ? "center"
            : position === "apart"
            ? "space-between"
            : position,
        gap: gap,
        flexDirection: direction,
        flexWrap:
          wrap === "wrap" ? "wrap" : wrap === "nowrap" ? "nowrap" : wrap,
        alignItems: align,
        marginTop: mt ?? my,
        marginBottom: mb ?? my,
        marginLeft: ml ?? mx,
        marginRight: mr ?? mx,
        width: w,
        height: h,
        backgroundColor: bg,
      }}
      className={styles.flex}
    >
      {children}
    </div>
  );
}

export default HacFlex;
